import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Media from "react-media"
import LayoutDirectory from "components/layoutDirectory"
import IconFacebook from "components/svg/IconFacebook"
import IconInstagram from "components/svg/IconInstagram"
import IconTwitter from "components/svg/IconTwitter"
import IconYouTube from "components/svg/IconYouTube"
import { Button } from "components/button"
import { noise } from "components/css/noise"
import AwardAnnouncement from "../components/svg/AwardAnnouncement"

const StyledPageWrap = styled.div`
  position: relative;
  z-index: 1;
  @media ( max-width: 64em ) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -50vw;
      width: 100vw;
      background-color: #fff;
      z-index: -1;
    }
  }
  @media ( min-width: 64em ) {
    display: grid;
    grid-template: 1fr / 40% calc(20% - 6em) 40%;
    grid-gap: 3em;
  }
`

const StyledBackdrop = styled.div`
  display: none;
  @media ( min-width: 64em ) {
    position: sticky;
    display: block;
    top: 0;
    grid-column: 3 / span 1;
    grid-row: 1;
    height: calc(100vh + 2em);
    margin-top: calc( 0em - 3.8125em - 3.3125em - 2.5em);
    margin-bottom: -2em;
    overflow: hidden;
    width: calc(100% + ((100vw - ((100% * 2.5))) / 2));
    .show-player & {
      width: calc(100vw - (200px + 3em) - ((100% * 2.5) - 100% + 3em) - 1px);
    }
    div {
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: -1px;
      left: 0;
    }
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,0.1);
      backdrop-filter: var(--backdrop-filter);
      opacity: 0.5;
    }
    ${noise}
  }
`


const StyledPageContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 2em 0;
  a:not(${Button}) {
    text-decoration: underline;
    .can-hover &:hover,
    &:active {
      color: var(--color-gray-text)
    }
  }
  & > div {
    margin-bottom: 3em;
  }
  @media ( min-width: 60em ) and ( max-width: 64em ) {
    padding-top: 3em;
  }
  @media ( min-width: 64em ) {
    grid-column: 1;
    grid-row: 1;
  }
`

const SocialLink = styled.a`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 1.375em;
    height: auto;
    &:not(:last-of-type) {
        margin-right: 1em;
    }
    svg path {
        fill: var(--color-black);
    }
    .can-hover &:hover svg path,
    &:active svg path{
      fill: var(--color-gray-text);
    }
`

const StyledFixedVideo = styled.div`
  position: sticky;
  z-index: 2;
  @media ( max-width: 64em ) {
    z-index: 10;
    top: 0;
    margin-top: -4.1875em;
    margin-top: calc( 0em - 3.8125em - 3.3125em - 2.5em);
    width: 100vw;
    margin-left: -6vw;
    padding-top: 4.1875em;
    padding-top: calc( 3.8125em + 3.3125em + 1em);
    background-color: var(--color-black);
  }
  @media ( min-width: 60em ) and ( max-width: 64em ) {
    margin-left: -6vw;
  }
  @media ( min-width: 64em ) {
    top: 0;
    max-height: 100vh;
    margin-top: -3.3125em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-column: 2 / span 2;
    grid-row: 1;
  }
`

const StyledFixedVideoContent = styled.figure`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: var(--color-black);
  div {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
  }
  iframe {
      width: 100% !important;
      height: 100% !important;
  }
  @media ( min-width: 64em ) {
    div {
      box-shadow: 0 0.25em 1.5em -0.5em rgba(0,0,0,0.3);
    }
  }
`

const AboutContact = styled.aside`
    display: flex;
    align-items: center;
    margin-top: 2em;
    span {
      display: block;
      margin: 0.25em 1em 0 0;
      text-transform: uppercase;
      font-size: 0.75em;
      letter-spacing: 0.1em;
      line-height: 1;
      white-space: nowrap;
    }
`




const AboutPage = ({ data }) => {

  /*
    let videoIds = []
    
    for ( let { node } of data.artists.edges ) {
        if ( node.interview_video !== '' ) {
            videoIds.push(node.interview_video)
        }
    }

    const videoIdsShuffled = videoIds.sort(function() {
        return .5 - Math.random()
    })

    const videoIdsShuffledSliced = videoIdsShuffled.slice(1)


    useEffect(() => {
    
        let videoIdsShuffledString = `${data.about.additional_videos},`
        
        for ( let videoId of videoIdsShuffledSliced ) {
            videoIdsShuffledString += `${videoId},`;
        }
        videoIdsShuffledString = videoIdsShuffledString.replace(/(^,)|(,$)/g, "")

        document.getElementById('video-playlist').src = `https://www.youtube.com/embed/${videoIdsShuffled[0]}?playlist=${videoIdsShuffledString}&autoplay=1&color=white&modestbranding=1&playsinline=1&rel=0`
    })
    */
    return (
      <>
        <Helmet>
            <title>{data.about.title} | {data.site.siteMetadata.title}</title>
        </Helmet>
        <LayoutDirectory>
          <StyledPageWrap>
            <Media query="(min-width: 64em)" render={() => (
                <StyledBackdrop>
                  <Img fluid={data.hero.childImageSharp.fluid} alt="" />
                </StyledBackdrop>
              )}
            />
            <StyledFixedVideo>
              <StyledFixedVideoContent>
                <div>
                  <iframe title="video-playlist" id="video-playlist" width="560" height="315" src="https://www.youtube-nocookie.com/embed/q_W756mErlc?autoplay=0&modestbranding=1&playsinline=1&rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </StyledFixedVideoContent>
            </StyledFixedVideo>
            <StyledPageContent>
              <h1 className="pageTitle"><strong>About</strong> the Project</h1>
              <div dangerouslySetInnerHTML={{ __html: data.about.content }} />
              <Button as="a"  href="https://www.theoutlawocean.com" target="_blank" rel="noopener noreferrer" title="The Outlaw Ocean Project">Learn More about the Journalism</Button>
              <AboutContact>
                <span>Connect with Ian</span>
                <SocialLink href="https://twitter.com/ian_urbina" target="_blank" rel="noopener noreferrer" title="Connect with Ian on Twitter"><IconTwitter /></SocialLink>
                <SocialLink href="https://www.facebook.com/IanUrbinaReporter/" target="_blank" rel="noopener noreferrer" title="Connect with Ian on Facebook"><IconFacebook /></SocialLink>
                <SocialLink href="https://www.instagram.com/ian_urbina" target="_blank" rel="noopener noreferrer" title="Connect with Ian on Instagram"><IconInstagram /></SocialLink>
                <SocialLink href="https://www.youtube.com/channel/UCykiIhv2wP4-BftEiKb241Q/featured" target="_blank" rel="noopener noreferrer" title="Connect with Ian on YouTube"><IconYouTube /></SocialLink>
              </AboutContact>
            </StyledPageContent>
          </StyledPageWrap>
        </LayoutDirectory>
        <AwardAnnouncement />
      </>
    )
}

export const query = graphql`
    query {
        about: directusAbout {
            title
            content
        }
        site {
            id
            siteMetadata {
                title
            }
        }
        hero: file(relativePath: { eq: "cover.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

export default AboutPage